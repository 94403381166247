<template>
  <div>
    <topbar v-show="!bulkList.length" drawer :title="navbarTitle" :bulk-list="bulkList"></topbar>
    <v-app-bar v-show="bulkList.length" ref="topbar" class="topbar">
      <v-btn icon @click="bulkList = []">
        <v-icon>fa-times</v-icon>
      </v-btn>
      <v-toolbar-title class="title">{{bulkList.length}} Ausgewählt</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn v-if="bulkList.length" class="mr-2" icon @click="deleteSelectedShips">
        <v-icon>fas fa-trash</v-icon>
      </v-btn>
      <v-btn v-if="bulkList.length" class="mr-2" icon @click="shipPropsDialog = true">
        <v-icon>fas fa-edit</v-icon>
      </v-btn>
    </v-app-bar>

    <v-container v-if="!loading">
      <v-container>

        <v-text-field v-model="shipFilter.shipName" outlined clearable dense hide-details label="Your custom ship name">
        <v-btn icon slot="prepend" small @click="descending = !descending">
          <v-icon small>{{descending ? 'fas fa-chevron-down' : 'fas fa-chevron-up'}}</v-icon>
        </v-btn>
        <v-icon slot="prepend-inner" color="grey" style="padding-top: 3px;" small>fa-search</v-icon>
        <v-icon slot="append-outer" :color="filterIconColor" @click="filterDrawer = !filterDrawer">fa-filter</v-icon>
        </v-text-field>
      </v-container>
      <v-container v-if="filteredShipsWithPages.length" fluid>
        <v-row dense>
        <v-col v-for="(ship, index) in filteredShipsWithPages" :key="index" cols="12" sm="6" md="6" lg="3" xl="2">
          <hangar-ship-list-item :document="ship" :bulk-list="bulkList" :add-ship-to-bulk-list="addShipToBulkList" />
        </v-col>
        </v-row>
      </v-container>
      <v-container v-if="!filteredShipsWithPages.length" fluid>
        <div class="d-flex justify-center mt-8">
          <div>No Ship Found</div>
        </div>
      </v-container>
      <v-pagination v-if="pages > 0" v-model="page" class="my-4" :length="pages"></v-pagination>
    </v-container>

    <v-navigation-drawer v-model="filterDrawer" disable-resize-watcher app right :width="drawerWidth">
      <v-toolbar class="custom-toolbar" elevation="0">
        <div class="d-flex pt-1">
          <h3 style="margin: 0;">Ship filter</h3>
        </div>
        <div style="margin-left: auto; display: flex; pt-1">
          <v-btn icon small @click="filterDrawer = false"><v-icon small>fas fa-times</v-icon></v-btn>
        </div>
      </v-toolbar>
      <v-container>
        <v-container>
          <v-select
            v-model="shipFilter.shipIds"
            :items="filteredShipIds"
            label="Ship name"
            class="custom-input-background mb-4"
            item-text="name"
            item-value="shipId"
            outlined
            multiple
            dense
            hide-details
          >
            <template v-slot:prepend-item @mousedown.stop @click.stop>
              <v-container>
                <v-text-field v-model="shipName" hide-details dense label="Avenger, Carrack, Cutlass"></v-text-field>
              </v-container>
            </template>
          </v-select>

          <v-select
            v-model="shipFilter.manufacturerIds"
            :items="filteredManufacturers"
            label="Manufacturers"
            class="custom-input-background mb-4"
            item-text="manufacturerName"
            item-value="manufacturerId"
            outlined
            multiple
            dense
            hide-details
          >
            <template v-slot:prepend-item @mousedown.stop @click.stop>
              <v-container>
                <v-text-field v-model="manufacturerName" hide-details dense label="Anvil, MISC, Origin"></v-text-field>
              </v-container>
            </template>
          </v-select>

          <v-select
            v-model="shipFilter.size"
            :items="shipSizes"
            label="Size"
            class="custom-input-background mb-4"
            item-text="name"
            item-value="name"
            outlined
            multiple
            dense
            hide-details
          >
          </v-select>

          <v-select
            v-model="hangarUserIds"
            :items="filteredHangarUsers"
            label="Member"
            class="custom-input-background mb-4"
            item-text="userName"
            item-value="userId"
            outlined
            multiple
            dense
            hide-details
          >
            <template v-slot:prepend-item @mousedown.stop @click.stop>
              <v-container>
                <v-text-field v-model="hangarUserName" hide-details dense label="Avenger, Carrack, Cutlass"></v-text-field>
              </v-container>
            </template>
          </v-select>
        </v-container>
        <div class="d-flex justify-center">
          <v-btn rounded depressed small color="primary" @click="resetFilter">Reset filter</v-btn>
        </div>
      </v-container>
    </v-navigation-drawer>

    <v-dialog v-model="shipPropsDialog" persistentmax-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Add props to ship</span>
        </v-card-title>
        <v-card-text>
          <span>After you klicked "{{editShip.data.length > 1 ? 'Update ships' : 'Update ship'}}" down below, your {{editShip.data.length > 1 ? 'ships' : 'ship'}} data will be updated (if it was successful)</span>
        </v-card-text>
        <v-select
          v-model="editShip.hangarShipId"
          :items="editShip.data"
          label="Select ship"
          class="custom-input-background mb-4"
          style="padding: 16px 32px 0px 32px;"
          item-text="shipName"
          item-value="hangarShipId"
          outlined
          dense
          hide-details
        >
        </v-select>
        <v-card-text>
          <v-container v-if="editShip.data.length">
            <v-row dense>
              <v-col cols="12" sm="9">
                <v-text-field v-model="editShip.data[editShip.index].shipName" label="Ship name" hint="Emty field = rsi standard name"></v-text-field>
              </v-col>
              <v-col cols="12" sm="3">
                <v-text-field v-model="editShip.data[editShip.index].price" label="Ship price" prefix="$" hint="Emty field = rsi standard price"></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <v-text-field v-model="editShip.data[editShip.index].images.small" label="Ship preview image" hint="Emty field = rsi standard image"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field v-model="editShip.data[editShip.index].images.big" label="Ship image" hint="Emty field = rsi standard image"></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" sm="4">
                <v-checkbox v-model="editShip.data[editShip.index].lti" label="LTI"></v-checkbox>
              </v-col>
              <v-col cols="12" sm="4">
                <v-checkbox v-model="editShip.data[editShip.index].warbond" label="Warbond"></v-checkbox>
              </v-col>
              <v-col cols="12" sm="4">
                <v-checkbox v-model="editShip.data[editShip.index].flagship" label="Flagship"></v-checkbox>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="shipPropsDialog = false">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="editShipProps">{{editShip.data.length > 1 ? 'Update ships' : 'Update ship'}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import Topbar from '../components/Topbar';
  import HangarShipListItem from '../components/HangarShipListItem.vue';


  export default {
    name: 'Ships',

    components: {
      Topbar,
      HangarShipListItem
    },

    props: {
      userId: {
        type: String,
        default: ''
      }
    },

    data() {
      return {
        navbarTitle: 'Hangar',
        page: 1,
        pages: 0,
        bulkList: [],
        maxVisibleItems: 16,
        filterDrawer: false,

        manufacturers: [],
        hangarUserName: '',
        hangarUserIds: [],
        filteredHangarUsers: [],
        shipName: '',
        filteredShipIds: [],
        filteredShips: [],
        manufacturerName: '',
        filteredManufacturers: [],
        shipSizes: [],
        shipFilter: {
          shipName: '',
          shipIds: [],
          manufacturerIds: [],
          size: []
        },
        descending: false,
        filteredShipsWithPages: [],
        filterIconColor: 'gray',
        shipPropsDialog: false,
        editShip: {
          index: 0,
          hangarShipId: [],
          data: []
        }
      };
    },

    computed: {
      loading() {
        return this.$store.getters.loading;
      },
      user() {
        return this.$store.getters['user/getUser'];
      },
      ships() {
        return this.$store.getters['hangar/getHangar'];
      },
      hangarUsers() {
        return this.$store.getters['hangar/getHangarUsers'];
      },
      shipFilterStore() {
        return this.$store.getters['hangar/getShipFilterStore'];
      },
      /**
       * get drawer with
       * @return {array}
       */
      drawerWidth() {
        let width = '';
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': width = '100%'; break;
          case 'sm': width = '80%'; break;
          case 'md': width = '40%'; break;
          case 'lg': width = '25%'; break;
          case 'xl': width = '25%';
        }
        return width;
      }
    },

    watch: {
      page() {
        this.setPages(this.filteredShips);
      },
      ships(ships) {
        // if (!ships.length) {return;}

        // set pages
        this.filteredShips = ships;
        this.setPages(this.filteredShips);

        // set size
        this.setShipSize(ships);

        // set name
        this.filteredShipIds = this.ships;

        // set manu
        this.setManufacturers();

        // set filter
        this.shipFilter = this.shipFilterStore;
      },

      hangarUsers(hangarUsers) {
        this.filteredHangarUsers = hangarUsers;
      },

      hangarUserName(name) {
        if (!name) {
          this.filteredHangarUsers = this.hangarUsers;
          return;
        }
        this.filteredHangarUsers = this.hangarUsers.filter((item) => item.userName.toLowerCase().includes(name.toLowerCase()));
      },

      async hangarUserIds(hangarUserIds) {
        this.$store.dispatch('loading', true, {root: true});
        await this.$store.dispatch('hangar/getHangar', {userId: this.user.userId, userIds: hangarUserIds});
        this.$store.dispatch('loading', false, {root: true});
      },

      shipName(name) {
        if (!name) {
          this.filteredShipIds = this.ships;
          return;
        }
        this.filteredShipIds = this.ships.filter((item) => item.shipName.toLowerCase().includes(name.toLowerCase()));
      },

      manufacturerName(name) {
        if (!name) {
          this.filteredManufacturers = this.manufacturers;
          return;
        }
        this.filteredManufacturers = this.manufacturers.filter((item) => item.manufacturerName.toLowerCase().includes(name.toLowerCase()));
      },

      shipFilter: {
        deep: true,
        handler(filter) {
          this.$store.dispatch('hangar/setShipFilterStore', filter);
          this.setFilter();
          this.setFilterIconColor();
        }
      },

      'editShip.hangarShipId': function name(hangarShipId) {
        this.editShip.index = this.editShip.data.findIndex((item) => item.hangarShipId === hangarShipId);
      },

      shipPropsDialog(open) {
        if (open) {
          const shipDataToEdit = [];
          for (let i = 0; i < this.bulkList.length; i++) {
            const shipData = this.ships.find((item) => item.hangarShipId === this.bulkList[i]);
            const noRefShip = JSON.parse(JSON.stringify(shipData));
            shipDataToEdit.push({
              userId: noRefShip.userId,
              userName: noRefShip.name,
              shipId: noRefShip.shipId,
              hangarShipId: noRefShip.hangarShipId,
              images: noRefShip.images,
              shipName: noRefShip.shipName,
              originShipName: noRefShip.originShipName,
              price: noRefShip.price,
              lti: noRefShip.lti,
              warbound: noRefShip.warbound,
              flagship: noRefShip.flagship
            });
          }
          this.editShip.data = shipDataToEdit;
          this.editShip.hangarShipId = this.bulkList[0];
          return;
        }
        this.editShip = {
          index: 0,
          hangarShipId: [],
          data: []
        }
      },
      descending() {
        this.setPages(this.filteredShips);
      }
    },

    beforeMount() {
      this.loadItems();
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    },

    methods: {
      async loadItems() {
        this.hangarUserIds = [];
        this.$store.dispatch('loading', true, {root: true});
        if (!this.user && this.user.userId) {
          await this.$store.dispatch('user/getUser', this.$auth && this.$auth.user.sub);
        }
        this.hangarUserIds.push(this.userId || this.user.userId);
      },
      setPages(ships) {
        this.pages = Math.ceil(ships.length / this.maxVisibleItems);
        const offset = (this.page - 1) * this.maxVisibleItems;
        const limit = this.maxVisibleItems;
        const sortedShips = this.sortShips(JSON.parse(JSON.stringify(ships)));
        this.filteredShipsWithPages = sortedShips.slice(offset, offset + limit);
      },
      setShipSize(ships) {
        const shipSizes = [];
        for (let i = 0; i < ships.length; i++) {
          if (!shipSizes.includes(ships[i].size) && ships[i].size !== 'N/A') {
            shipSizes.push(ships[i].size);
          }
        }
        this.shipSizes = shipSizes;
      },
      setManufacturers() {
        const manufacturers = [];
        for (let i = 0; i < this.ships.length; i++) {
          const hasManufacturer = manufacturers.find((item) => item.manufacturerId === this.ships[i].manufacturerId);
          if (!hasManufacturer) {
            manufacturers.push({manufacturerId: this.ships[i].manufacturerId, manufacturerName: this.ships[i].manufacturerName});
          }
        }
        this.manufacturers = manufacturers;
        this.filteredManufacturers = manufacturers;
      },
      setFilter() {
        let ships = this.ships;
        for (const key in this.shipFilter) {
          if (Object.hasOwnProperty.call(this.shipFilter, key)) {
            if (key === 'shipName' && this.shipFilter[key]) {
              ships = ships.filter((item) => item.shipName.toLowerCase().includes(this.shipFilter[key].toLowerCase()));
            }
            if (key === 'shipIds' && this.shipFilter[key].length) {
              ships = ships.filter((item) => this.shipFilter[key].includes(item.shipId));
              this.filteredManufacturers = this.manufacturers.filter((manufacturer) => {
                const hasShip = ships.find((ship) => ship.manufacturerId === manufacturer.manufacturerId);
                if (hasShip) {
                  return true;
                }
                return false;
              });
            }
            if (key === 'shipIds' && !this.shipFilter[key].length) {
              this.filteredManufacturers = this.manufacturers;
            }

            if (key === 'manufacturerIds' && this.shipFilter[key].length) {
              ships = ships.filter((item) => this.shipFilter[key].includes(item.manufacturerId));
               this.filteredShipIds = this.ships.filter((ship) => {
                const hasManufacturer = ships.find((manufacturer) => manufacturer.manufacturerId === ship.manufacturerId);
                if (hasManufacturer) {
                  return true;
                }
                return false;
              });
            }
            if (key === 'manufacturerIds' && !this.shipFilter[key].length) {
              this.filteredShipIds = this.ships;
            }
            if (key === 'size' && this.shipFilter[key].length) {
              ships = ships.filter((item) => this.shipFilter[key].includes(item.size));
            }
          }
        }
        this.setShipSize(ships);
        this.filteredShips = ships;
        if (this.page !== 1) {
          this.page = 1;
          return;
        }
        this.setPages(this.filteredShips);
      },
      setFilterIconColor() {
        let color = 'gray';
        for (const key in this.shipFilter) {
          if (Object.hasOwnProperty.call(this.shipFilter, key)) {
            if (key !== 'shipName' && this.shipFilter[key].length) {
              color = 'red';
            }
          }
        }
        this.filterIconColor = color;
      },
      addShipToBulkList(hangarShipId) {
        if (!this.bulkList.includes(hangarShipId)) {
          this.bulkList.push(hangarShipId);
          return;
        }
        this.bulkList = this.bulkList.filter((item) => item !== hangarShipId);
      },
      async editShipProps() {
        await this.$store.dispatch('hangar/updateShips', this.editShip.data);
        this.shipPropsDialog = false;
        this.bulkList = [];
        this.$swal({
          toast: true,
          timer: 3000,
          position: 'top-end',
          title: 'success',
          icon: 'success',
          showConfirmButton: false
        });
        await this.loadItems();
      },
      deleteSelectedShips() {
        this.$swal({
          title: 'Markierte Schiffe löschen?',
          icon: 'warning',
          showCancelButton: true,
          showLoaderOnConfirm: true,
          cancelButtonText: 'Nein',
          confirmButtonText: 'Ja',
          preConfirm: async () => {
            try {
              this.shipPropsDialog = false;
              await this.$store.dispatch('hangar/deleteShips', this.bulkList);
              this.bulkList = [];
              this.loadItems();
              this.$swal({
                toast: true,
                timer: 3000,
                position: 'top-end',
                title: 'success',
                icon: 'success',
                showConfirmButton: false
              });
            } catch (error) {
              // NOOP
            }
          }
        });
      },
      sortShips(ships) {
        const sortedShips = ships;
        if (this.descending) {
          sortedShips.sort(function (a, b) {
            if (a.name > b.name) {
                return -1;
            }
            if (b.name > a.name) {
                return 1;
            }
            return 0;
          });
        } else {
          sortedShips.sort(function (a, b) {
            if (a.name > b.name) {
                return 1;
            }
            if (b.name > a.name) {
                return -1;
            }
            return 0;
          });
        }
        return sortedShips;
      },
      resetFilter() {
        this.filteredHangarUsers = this.hangarUsers;
        this.hangarUserIds = [this.user.userId];
        this.$store.dispatch('hangar/resetShipFilterStore');
        this.shipFilter = {
          shipName: '',
          shipIds: [],
          manufacturerIds: [],
          size: []
        }
        this.filterDrawer = false;
        this.setFilterIconColor();
      }
    }
  };
</script>

<style scoped>
  .custom-toolbar {
    position: sticky;
    top: 0;
    z-index: 100;
  }
</style>
