<template>
  <v-card dense>
    <v-img ref="smallimg" :src="document.images.small" class="white--text align-end" height="200px" @error="imageError = true">
      <v-icon v-if="document.flagship" style="position: absolute; top: 12px; right: 12px;" color="yellow">fas fa-star</v-icon>
      <div style="position:relative; background:rgba(0,0,0, 0.4);">

        <v-hover v-slot="{ hover }">
          <v-card-title :class="{ 'custom-anchor': hover }" class="text-truncate" style="max-width:260px; display:block; cursor: pointer;" @click="shipDetail">{{hover ? document.shipName : document.name}}</v-card-title>
        </v-hover>
        <v-hover v-slot="{ hover }">
          <v-card-subtitle :class="{ 'custom-anchor': hover }" style="cursor: pointer;" @click="userDetail(document.userId)">{{document.userName}}</v-card-subtitle>
        </v-hover>
        <v-btn v-if="document.userId === user.userId" icon style="position: absolute; bottom: 36px; right: 10px;" @click="addShipToBulkList(document.hangarShipId)">
          <v-icon :color="bulkList.includes(document.hangarShipId) ? 'green' : 'white'">{{bulkList.includes(document.hangarShipId) ? 'far fa-check-circle' : 'far fa-circle'}}</v-icon>
        </v-btn>
      </div>
      <template v-slot:placeholder>
        <v-row
          v-if="!imageError"
          class="fill-height mb-6"
          align="center"
          justify="center"
        >
          <v-progress-circular
            indeterminate
            color="blue"
          ></v-progress-circular>
        </v-row>
        <v-img v-if="imageError" max-height="240" cover :src="require('../assets/images/no-img.png')" />
      </template>
    </v-img>
  </v-card>
</template>

<script>

  export default {
    name: 'NavItem',

    props: {
      document: {
        type: Object,
        default: () => {}
      },
      bulkList: {
        type: Array,
        default: () => {
          return [];
        }
      },
      addShipToBulkList: {
        type: Function,
        default: () => {}
      }
    },

    data: () => ({
      shipMeta: [],
      shipOwnerName: '',
      showBulkAction: false,
      hasShipInCard: false,
      image: '',
      imageError: false
    }),

    computed: {
      user() {
        return this.$store.getters['user/getUser'];
      }
    },

    watch: {
      bulkList(bulkList) {
        if (!bulkList.length) {
          this.showBulkAction = false;
        }
        this.hasShipInCard = bulkList.find((item) => item.shipId === document.shipId);
      }
    },

    methods: {
      toggleShipMeta(shipId) {
        const shipIndex = this.shipMeta.indexOf(shipId);
        if (shipIndex === -1) {
          this.shipMeta.push(shipId);
          return;
        }
        this.shipMeta.splice(shipIndex, 1);
      },
      shipDetail() {
        this.$router.push({name: 'HangarShipDetail', query: {hangarShipId: this.document.hangarShipId}});
      },
      userDetail(userId) {
        this.$router.push({name: 'user', query: {userId}});
      }
    }
  };
</script>

<style lang="scss" scoped>
.custom-expand {
  z-index: 100;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  background: white;
  height: 100%;
  max-height: 204px;
}

.custom-anchor {
  color: skyblue;
  text-decoration: underline;
}
</style>
